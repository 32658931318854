@import '../../global.scss';

.actionsContainer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	justify-content: center;
	align-items: center;
	gap: 1rem;
	margin-bottom: 2rem;
}

.tasksContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 4rem;

	.tasksInfoWrapper {
		margin: 2rem 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		width: 100%;
		max-width: 400px;

		.tasksInfo {
			display: flex;
			gap: 0 0.5rem;
			align-items: baseline;
		}
	}
}

.serverStatusTitle {
	margin: 2rem 0;
}

.hiddenTab {
	display: none;
}

.shownTab {
	display: contents;
}

.navMenu {
	margin: 0;
	padding: 0;
	border-radius: 5px;
	overflow: hidden;
	background-color: white;
	box-shadow: 0 0 10px #0000001a;

	width: 378px;
	max-width: 100%;
	color: black;
	text-decoration: none;

	padding: 10px 0;

	text-align: center;

	.normalLink {
		padding: 0 10px;
		margin: 0 10px;
		color: black;
		text-decoration: none;
	}

	.normalLink:hover {
		text-decoration: underline;
	}

	.boldLink {
		padding: 0 10px;
		margin: 0 10px;
		color: black;
		text-decoration: none;
		font-weight: bold;
	}

	.boldLink:hover {
		text-decoration: none;
	}
}

.serverUsage {
	margin: 0;
	padding: 0;
	border-radius: 5px;
	box-shadow: 0 0 10px #0000001a;
	overflow: hidden;
	background-color: white;

	width: 378px;
	max-width: 100%;
	cursor: pointer;
	color: black;
	text-decoration: none;

	margin-bottom: 1.313rem;

	.serverUsageHeader {
		padding: 10px;
		border-bottom: 1px solid silver;
		text-align: left;
		margin-left: 10px;
		max-width: calc(100% - 20px);
	}

	.serverUsageTabHolder {
		display: flex;
	}

	.serverUsageTab {
		display: inline-block;
		position: relative;
		flex: 1;
		margin: 0;
		padding: 0;
		border-bottom: 10px solid silver;

		.sameHeight {
			margin-top: 75%;
		}

		.serverUsageElement {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
			padding: 10px;

			.serverUsageTitle {
				margin-bottom: 15px;
			}
		}
	}

	.serverUsageRed {
		border-bottom: 10px solid #F24E1E;
	}

	.serverUsageYellow {
		border-bottom: 10px solid #FFB627;
	}

	.serverUsageGreen {
		border-bottom: 10px solid #32E19E;
	}

	.serverUsageGray {
		border-bottom: 10px solid silver;
	}

}
