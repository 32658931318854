@import '../../global.scss';

.dropdownContainer {
    position: relative;
    border-radius: $border-radius;
    background-color: $primary-background-color;
    min-width: 100px;

    &[disabled] {
        pointer-events: none;
        opacity: 0.3;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: $border-radius;
        }
    }

    .dropdownHeader {
        font-family: Plus Jakarta Sans;
        font-size: var(--font-size-small);
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        padding: 0.5rem 0.625rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0 0.5rem;
    }

    .dropdownBody {
        padding: 5px;
        border-top: 1px solid #E5E8EC;
        background-color: $primary-background-color;
        display: none;
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        z-index: 10;
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
        border-radius: $border-radius;

        &.open {
            display: block;
        }
    }


    .dropdownItem {
        display: flex;
        align-items: center;
        gap: 0 0.5rem;
        padding: 10px;
        font-family: Plus Jakarta Sans;
        font-size: var(--font-size-small);
        border-radius: $border-radius;
    }

    .dropdownItem:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05);
    }

    .dropdownItemDot {
        opacity: 0;
        color: #91A5BE;
        transition: all 0.2s ease-in-out;

        &.selected {
            opacity: 1;
        }
    }
}
